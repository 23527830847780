import EmailVerifyRequired from '@/components/EmailVerifyRequired';
import { removeBackUrl } from '@/lib/localStorage';
import { getCurrentUser, signOut } from '@/lib/firebase-client';

function EmailVerifyRequiredContainer() {
  const undo = async () => {
    removeBackUrl();
    await signOut();
  };

  const currentUser = getCurrentUser();

  // まだsessionクッキーを作成してないのでcurrentUser.emailを使用する。
  // currentUser が存在するときにこのコンポーネントが表示される
  return <EmailVerifyRequired email={currentUser?.email ?? ''} onClickUndo={undo} />;
}

export default EmailVerifyRequiredContainer;
