import Heading1 from '@/components/typography/Heading1';
import Text from '@/components/typography/Text';
import { useTranslation } from '@/hooks/i18n';
import { ContainedButton } from '@toyokumo/kintoneapp-ui';

type EmailSendProps = {
  emailValue: string;
  onClickBack: () => void;
};

function EmailSend({ emailValue, onClickBack }: EmailSendProps) {
  const { t } = useTranslation();

  return (
    <div>
      <Heading1>{t('login.emailSend')}</Heading1>
      <div className="mt-4">
        <Text>{t('login.emailSend.description', { email: emailValue })}</Text>
      </div>
      <div className="mt-10">
        <ContainedButton full onClick={onClickBack}>
          {t('back')}
        </ContainedButton>
      </div>
    </div>
  );
}

export default EmailSend;
