type Props = {
  children: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => void;
  icon?: React.ReactNode;
  disabled?: boolean;
};

// 文字だけのボタン
function TextButton({ children, icon, disabled, onClick }: Props) {
  return (
    <button
      type="button"
      className="text-sm font-bold text-role-action"
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <div className="inline-flex items-center space-x-1">
        <div>{children}</div>
        {icon && <div>{icon}</div>}
      </div>
    </button>
  );
}

export default TextButton;
