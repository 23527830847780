import Text from '@/components/typography/Text';
import { useTranslation } from '@/hooks/i18n';
import Heading1 from '@/components/typography/Heading1';
import { ContainedButton } from '@toyokumo/kintoneapp-ui';

type EmailVerifyRequiredProps = {
  onClickUndo: () => void;
  email: string;
};

function EmailVerifyRequired({ onClickUndo, email }: EmailVerifyRequiredProps) {
  const { t } = useTranslation();
  return (
    <div>
      <Heading1>{t('login.emailSend')}</Heading1>
      <div className="mt-4">
        <Text>{t('login.emailVerifyRequired', { email })}</Text>
      </div>
      <div className="mt-8">
        <ContainedButton full onClick={onClickUndo}>
          {t('back')}
        </ContainedButton>
      </div>
    </div>
  );
}

export default EmailVerifyRequired;
