import BasicLink from '@/components//BasicLink';
import SubText from '@/components/typography/SubText';
import Heading1 from '@/components/typography/Heading1';
import GoogleIcon from '@/svgs/icons/google.svg';
import MicrosoftIcon from '@/svgs/icons/microsoft.svg';
import EmailIcon from '@/svgs/icons/email.svg';
import { useState } from 'react';
import { useTranslation } from '@/hooks/i18n';
import { OutlinedButton } from '@toyokumo/kintoneapp-ui';

type LoginSelectProps = {
  onClickGoogleLogin: () => void;
  onClickMsLogin: () => void;
  onClickEmailLogin: () => void;
};

function LoginSelect({ onClickGoogleLogin, onClickMsLogin, onClickEmailLogin }: LoginSelectProps) {
  const [selectedAuthProvider, setSelectedAuthProvider] = useState<undefined | 'google' | 'ms'>(undefined);
  const { t } = useTranslation();

  return (
    <div>
      <Heading1>{t('login')}</Heading1>
      <div className="mt-4 space-y-4">
        <OutlinedButton
          full
          onClick={() => {
            setSelectedAuthProvider('google');
            onClickGoogleLogin();
          }}
          leftIcon={<GoogleIcon />}
          loading={selectedAuthProvider === 'google'}
          disabled={selectedAuthProvider != null && selectedAuthProvider !== 'google'}
        >
          {t('login.google')}
        </OutlinedButton>
        <OutlinedButton
          full
          onClick={() => {
            setSelectedAuthProvider('ms');
            onClickMsLogin();
          }}
          leftIcon={<MicrosoftIcon />}
          loading={selectedAuthProvider === 'ms'}
          disabled={selectedAuthProvider != null && selectedAuthProvider !== 'ms'}
        >
          {t('login.ms')}
        </OutlinedButton>
        <OutlinedButton
          full
          onClick={onClickEmailLogin}
          leftIcon={<EmailIcon />}
          disabled={selectedAuthProvider != null}
        >
          {t('login.email')}
        </OutlinedButton>
      </div>
      <div className="mt-4">
        <SubText>
          {t('login.toSignIn')}
          <BasicLink href="https://kintoneapp.com/terms.html" target="_blank">
            {t('termsOfService')}
          </BasicLink>
          {t('login.and')}
          <BasicLink href="https://toyokumo.co.jp/policy/" target="_blank">
            {t('privacyPolicy')}
          </BasicLink>
          {t('login.agreeWithTermsAndPolicy')}
        </SubText>
      </div>
    </div>
  );
}

export default LoginSelect;
