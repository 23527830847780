import {
  getProviderBySignInMethod,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithGoogle,
} from '@/lib/firebase-client';
import { useCallback, useContext, useState } from 'react';
import { NeedConfirmationErrorContext, SetNeedConfirmationErrorContext } from '@/contexts/account-linking';
import type { AuthCredential } from 'firebase/auth';
import { linkWithCredential, OAuthProvider, SignInMethod } from 'firebase/auth';
import { useSignInMethod } from '@/hooks/auth';
import { ContainedButton, Spinner, TextInput } from '@toyokumo/kintoneapp-ui';
import type { FirebaseError } from '@firebase/util';
import { useIsJa, useTranslation } from '@/hooks/i18n';
import Heading1 from '@/components/typography/Heading1';
import Text from '@/components/typography/Text';
import TextButton from '@/components/buttons/TextButton';

function GoogleLinking({
  email,
  onClickGoogleLogin,
  onCancel,
  isPending,
}: {
  email: string;
  onClickGoogleLogin: () => void;
  onCancel: () => void;
  isPending: boolean;
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Heading1>{t('login.accountLink.title')}</Heading1>
      <div className="mt-4">
        <Text>
          {email}
          {t('login.accountLink.google.ms')}
        </Text>
      </div>
      <div className="mt-10 flex flex-col space-y-2">
        <ContainedButton full onClick={onClickGoogleLogin} loading={isPending}>
          {t('login.accountLink')}
        </ContainedButton>
        <TextButton onClick={onCancel}>{t('cancel')}</TextButton>
      </div>
    </div>
  );
}

function EmailLinkLinking({
  email,
  onSubmitEmailLink,
  onCancel,
  isPending,
}: {
  email: string;
  onSubmitEmailLink: (emailLink: string) => void;
  onCancel: () => void;
  isPending: boolean;
}) {
  const { t } = useTranslation();
  const [emailLink, setEmailLink] = useState('');
  const [isDoingEmailLogin, setIsDoingEmailLogin] = useState(false);
  const isJa = useIsJa();

  const onClickSendEmail = useCallback(async () => {
    setIsDoingEmailLogin(true);
    sendSignInLinkToEmail({ email, isJa });
  }, [email, isJa]);

  return (
    <div>
      <Heading1>{t('login.accountLink.title')}</Heading1>
      {isDoingEmailLogin ? (
        <>
          <div className="mt-4">
            <Text>{t('login.accountLink.sentMailForLink')}</Text>
          </div>
          <div className="mt-10 flex flex-col space-y-2">
            <div>
              <TextInput
                id="emailLink"
                value={emailLink}
                onChange={(e) => setEmailLink(e.target.value)}
                placeholder="https://auth.account.kintoneapp.com/__/auth/action..."
              />
            </div>
            <ContainedButton
              full
              onClick={() => onSubmitEmailLink(emailLink)}
              loading={isPending}
              disabled={emailLink.length === 0}
            >
              {t('login.accountLink')}
            </ContainedButton>
          </div>
        </>
      ) : (
        <>
          <div className="mt-4">
            <Text>
              {email}
              {t('login.accountLink.mail.ms')}
            </Text>
          </div>
          <div className="mt-10 flex flex-col space-y-2">
            <ContainedButton full onClick={onClickSendEmail} loading={isPending}>
              {t('login.accountLink.sendMail')}
            </ContainedButton>
          </div>
        </>
      )}
      <div className="mt-2 text-center">
        <TextButton onClick={onCancel}>{t('cancel')}</TextButton>
      </div>
    </div>
  );
}

const getPendingMethodAndCredential = (error: FirebaseError) => {
  const pendingCred = OAuthProvider.credentialFromError(error) as AuthCredential;
  const pendingMethod = pendingCred.providerId;
  return { pendingMethod, pendingCred };
};

// eslint-disable-next-line import/prefer-default-export
export function AccountLinkingContainer() {
  const needConfirmationError = useContext(NeedConfirmationErrorContext);
  const setNeedConfirmationError = useContext(SetNeedConfirmationErrorContext);
  const { isPending, data: signInMethod } = useSignInMethod(
    (needConfirmationError?.customData as { email: string })?.email,
  );
  const linkageMethod = signInMethod != null && signInMethod.length > 0 ? signInMethod[0] : null;
  const [isLinking, setIsLinking] = useState(false);
  const { t } = useTranslation();

  const onCancel = () => {
    setNeedConfirmationError(null);
  };
  const linkWithGoogle = async () => {
    if (!needConfirmationError) return;
    setIsLinking(true);
    const { pendingMethod, pendingCred } = getPendingMethodAndCredential(needConfirmationError);
    const provider = getProviderBySignInMethod(pendingMethod);
    if (!provider) {
      alert(t('login.accountLink.unsupportedLogin'));
      setNeedConfirmationError(null);
      return;
    }
    const result = await signInWithGoogle();
    await linkWithCredential(result.user, pendingCred);
    setNeedConfirmationError(null);
  };

  const linkWithEmailLink = async (emailLink: string) => {
    if (!needConfirmationError) return;
    setIsLinking(true);
    const { email } = needConfirmationError?.customData as { email: string };
    const { pendingCred } = getPendingMethodAndCredential(needConfirmationError);
    try {
      const result = await signInWithEmailLink(email, emailLink, { t });
      await linkWithCredential(result.user, pendingCred);
    } catch (_) {
      alert(t('login.accountLink.failedLogin'));
    }
    setNeedConfirmationError(null);
  };

  if (isPending || linkageMethod == null)
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );

  if (linkageMethod === SignInMethod.GOOGLE) {
    return (
      <GoogleLinking
        email={needConfirmationError?.customData?.email as string}
        onClickGoogleLogin={linkWithGoogle}
        onCancel={onCancel}
        isPending={isLinking}
      />
    );
  }

  return (
    <EmailLinkLinking
      email={needConfirmationError?.customData?.email as string}
      onSubmitEmailLink={linkWithEmailLink}
      onCancel={onCancel}
      isPending={isLinking}
    />
  );
}
