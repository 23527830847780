import { useRouter } from 'next/router';
import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { sendSignInLinkToEmail } from '@/lib/firebase-client';
import { storeEmail } from '@/lib/localStorage';
import EmailLoginForm from '@/components/EmailLoginForm';
import EmailSend from '@/components/EmailSend';
import { useIsJa } from '@/hooks/i18n';

type EmailLoginFormContainerProps = {
  callback: () => void;
};

function EmailLoginFormContainer({ callback }: EmailLoginFormContainerProps) {
  const router = useRouter();
  const isJa = useIsJa();

  const [emailInput, setEmailInput] = useState('');
  const [isSendMail, setIsSendMail] = useState(false);
  const [isSendingMail, setIsSendingMail] = useState(false);

  const login = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSendingMail(true);
    const { backUrl } = router.query;
    const burl = typeof backUrl === 'string' ? backUrl : undefined;
    sendSignInLinkToEmail({ email: emailInput, backUrl: burl, isJa })
      .then(() => {
        storeEmail(emailInput);
        setIsSendMail(true);
        setIsSendingMail(false);
      })
      .catch((_) => {
        // TODO エラーの見せ方
        setIsSendingMail(false);
      });
  };

  const input = (e: ChangeEvent<HTMLInputElement>) => setEmailInput(e.target.value);
  const onCancel = () => {
    setEmailInput('');
    callback();
  };
  const back = () => setIsSendMail(false);

  if (isSendMail) {
    return <EmailSend emailValue={emailInput} onClickBack={back} />;
  }
  return (
    <EmailLoginForm
      onCancel={onCancel}
      onSubmit={login}
      onChangeEmailValue={input}
      emailValue={emailInput}
      isSendingEmail={isSendingMail}
    />
  );
}

export default EmailLoginFormContainer;
