import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { restoreEmail, storeBackUrl } from '@/lib/localStorage';
import {
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailLink as _signInWithEmailLink,
  signOut,
} from '@/lib/firebase-client';
import { useAuthContext, useLogin } from '@/hooks/auth';
import { useTrackEvent } from '@/hooks/amplitude';
import { EN_CLICK_SIGN_IN, EP_SIGN_IN_METHOD, getSignInMethodName, UP_LAST_SIGN_IN_AT } from '@/config/amplitude';
import * as amplitude from '@amplitude/analytics-browser';
import dayjs from 'dayjs';
import { NeedConfirmationErrorContext, SetNeedConfirmationErrorContext } from '@/contexts/account-linking';
import type { FirebaseError } from '@firebase/util';
import { useTranslation } from '@/hooks/i18n';

type AuthStateObserverProps = {
  loading: React.ReactNode;
  emailVerifying: React.ReactNode;
  content: React.ReactNode;
  accountLinking: React.ReactNode;
};

function AuthStateObserver({ emailVerifying, loading, content, accountLinking }: AuthStateObserverProps) {
  const [isPending, setIsPending] = useState(true);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isEmailVerifying, setIsEmailVerifying] = useState(false);

  // State5: 要アカウントリンク
  // すでに他の方法でログインしていたことが元で発生するMSログインのエラー。非nullの時にはアカウントリンクが必要な状態であるとする。
  // onAuthStateChangedで検知できないため(Set)NeedConfirmationErrorContextを通じて値をセット/取得する
  const [needConfirmationError, setNeedConfirmationError] = useState<FirebaseError | null>(null);

  const router = useRouter();
  const authedUser = useAuthContext();
  const { mutate: login } = useLogin();
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();

  // マジックリンクを使ってFirebaseにログインする
  const signInWithEmailLink = useCallback(
    async (url: string) => {
      let email = restoreEmail();
      if (email == null) {
        email = prompt(`${t('login.EnterLoginEmail')}`) ?? '';
      }
      try {
        await _signInWithEmailLink(email, url, { t });
      } catch (_) {
        await router.push('/login');
        setIsPending(false);
      }
    },
    [router, t],
  );

  // 状態遷移については次のドキュメントを参照すること
  // https://scrapbox.io/toyokumo/account-kintoneapp_FirebaseAuthStateの状態遷移図
  useEffect(
    // React 18 から StrictModeが有効な開発時のみuseEffect2回実行されるようになり、メールリンクログインの際にメールを送信を行ったブラウザであってもメールアドレスの確認が行われてしまうようになった。
    // 可能性はほぼないが本番でも起こりうるのでエフェクトが再マウントされた時でも適切に動作するように以下を参考に2回目以降を無視する。
    // https://react.dev/learn/synchronizing-with-effects#fetching-data
    () => {
      let ignore = false;

      onAuthStateChanged(async (user) => {
        if (ignore) return;
        // State6: kintoneApp認証完了(sessionCookieを用いてユーザーが取得できた)
        if (authedUser != null) return;

        if (user) {
          if (user.emailVerified) {
            // State2: Firebaseログイン済(email確認済)
            setIsRedirecting(true);
            const { signInProvider, token } = await user.getIdTokenResult();

            login(token, {
              onError: () => {
                // TODO toastを出すなどの処理をする
                setIsRedirecting(false);
              },
              onSuccess: ({ uid }) => {
                amplitude.setUserId(uid);
                const identifyEvent = new amplitude.Identify();
                identifyEvent.set(UP_LAST_SIGN_IN_AT, dayjs().format('YYYY/M/D'));
                amplitude.identify(identifyEvent);

                const signInMethodName = getSignInMethodName(signInProvider ?? '');
                trackEvent(EN_CLICK_SIGN_IN, {
                  [EP_SIGN_IN_METHOD]: signInMethodName,
                });
              },
            });
            // ログインの成功・失敗に関わらずFirebaseからサインアウト
            await signOut();
          } else if (isEmailVerifying) {
            // State3: Firebaseログイン済(email未確認)
            await sendEmailVerification(user, () => {
              setIsEmailVerifying(false);
            });
          } else {
            setIsEmailVerifying(true);
          }
          return;
        }

        // State4: マジックリンクを踏んだ人をログインさせる
        if (isSignInWithEmailLink(router.asPath) && !isRedirecting) {
          storeBackUrl(router);
          await signInWithEmailLink(router.asPath);
          return;
        }

        // State1: 未ログイン or State5: 要アカウントリンク
        // ここまで来たらアカウントリンクを除いて必要な処理は終わっているので、アカウントリンク以外の状態をリセットする
        setIsPending(false);
        setIsEmailVerifying(false);
      });
      // useEffectの初回マウントを適切にクリーンアップする
      return () => {
        ignore = true;
      };
    },
    // router の変化に伴い useEffect が再実行されるのは避けたいので除外.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRedirecting, isEmailVerifying, authedUser, needConfirmationError],
  );

  if (isEmailVerifying) {
    return <>{emailVerifying}</>;
  }

  if (needConfirmationError != null) {
    return (
      <SetNeedConfirmationErrorContext.Provider value={setNeedConfirmationError}>
        <NeedConfirmationErrorContext.Provider value={needConfirmationError}>
          {accountLinking}
        </NeedConfirmationErrorContext.Provider>
      </SetNeedConfirmationErrorContext.Provider>
    );
  }

  if (isPending || isRedirecting) {
    return <>{loading}</>;
  }

  return (
    <SetNeedConfirmationErrorContext.Provider value={setNeedConfirmationError}>
      {content}
    </SetNeedConfirmationErrorContext.Provider>
  );
}

export default AuthStateObserver;
