import BasicLink from '@/components/BasicLink';
import Text from '@/components/typography/Text';
import { useState } from 'react';
import WhatIsKintoneAppAuthDialog from '@/components/WhatIsKintoneAppAuthDialog';
import TextButton from '@/components/buttons/TextButton';
import { useTrackEvent } from '@/hooks/amplitude';
import { EN_CLICK_OPEN_WHAT_IS_KINTONEAPP_AUTH } from '@/config/amplitude';

function FooterText({ showDialog }: { showDialog: (e: React.SyntheticEvent) => void }) {
  return (
    <Text>
      トヨクモ kintone連携サービスへ
      <TextButton onClick={showDialog}>
        <span className="font-normal">Toyokumo kintoneApp認証</span>
      </TextButton>
      で簡単・安全にログインできます。ヘルプは
      <BasicLink href="https://kintoneapp.com/lp/kintoneapp-auth/faq.html" target="_blank">
        こちら
      </BasicLink>
      。
    </Text>
  );
}

function Footer() {
  const [isOpen, setIsOpen] = useState(false);
  const trackEvent = useTrackEvent();
  const showDialog = (e: React.SyntheticEvent) => {
    trackEvent(EN_CLICK_OPEN_WHAT_IS_KINTONEAPP_AUTH);
    setIsOpen(true);
    e.preventDefault();
  };

  return (
    <div className="max-w-screen-sm bg-toyokumo-gray-50 px-4 py-2">
      <FooterText showDialog={showDialog} />
      <WhatIsKintoneAppAuthDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}

export default Footer;
