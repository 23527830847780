import type { ChangeEvent } from 'react';
import Heading1 from '@/components/typography/Heading1';
import Text from '@/components/typography/Text';
import TextButton from '@/components/buttons/TextButton';
import { useTranslation } from '@/hooks/i18n';
import React from 'react';
import { ContainedButton, TextInput } from '@toyokumo/kintoneapp-ui';

type EmailLoginFormProps = {
  onCancel: () => void;
  onSubmit: (event: React.SyntheticEvent) => void;
  onChangeEmailValue: (event: ChangeEvent<HTMLInputElement>) => void;
  emailValue: string;
  isSendingEmail: boolean;
};

function EmailLoginForm({ onCancel, onSubmit, onChangeEmailValue, emailValue, isSendingEmail }: EmailLoginFormProps) {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <Heading1>{t('login.email')}</Heading1>
      <Text>{t('login.email.description')}</Text>
      <form className="flex flex-col" onSubmit={onSubmit}>
        <TextInput
          id="email"
          type="email"
          placeholder="example@example.com"
          value={emailValue}
          onChange={onChangeEmailValue}
          label={t('emailAddress') as string}
        />
        <div className="mt-10 flex flex-col space-y-2">
          {/* HTML5のメールアドレスバリデーションのためにformタグのonSubmitを動かす */}
          <ContainedButton
            full
            type="submit"
            onClick={() => false}
            loading={isSendingEmail}
            disabled={emailValue === ''}
          >
            {t('send')}
          </ContainedButton>
          <TextButton onClick={onCancel}>{t('cancel')}</TextButton>
        </div>
      </form>
    </div>
  );
}

export default EmailLoginForm;
